import {appGetUrlPath} from '../function_lib/common_cms';
import TextToHtml from '../sample_comp_v2/text_html';
import './comp_how_we_do.css'

const Comp_How_We_Do=(props)=>{
    let appPath=appGetUrlPath();
 
let title="";
if(props.lsData.length!=0){
title=props.lsData[0]["g_title"];
}

let topText="";
if(props.lsData.length!=0){
    if(props.lsData[0]["col10"].trim()==="top_text"){
        title=props.lsData[0]["col1"];
        topText=props.lsData[0]["col2"]+props.lsData[0]["col3"];
    }

}

    return(

        <div class="container-fluid howwedo_container my-5 bg-area1 pb-70px pt-70px">
    <h2 class="fw-bold mb-4">{title}</h2>
    <p><TextToHtml text={topText}/></p>
    <div class="features-container pt-5">
        {props.lsData.map((item,index)=>{
            if (item["col10"].trim()!=="top_text"){
                return <div class="feature-box border-box-2">
                <div class="icon text-primary">
                
                <img style={{"width":"60px"}} class="mx-auto" src={appPath+item["col4"]} alt=""/>
                 </div>
                <div class="text-start">
                    <h4 class="center"><center>{item["col1"]}</center></h4>
                     <p class="p-0"> 
                     <TextToHtml text={item["col2"]}/>
                        </p>
                </div>
            </div>
            }
            
        })}
    </div>

</div>

    );
}

export default Comp_How_We_Do;