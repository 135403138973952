import {appGetUrlPath} from '../function_lib/common_cms';
import TextToHtml from '../sample_comp_v2/text_html';
import './comp_how_we_top_icon.css';


const Comp_How_We_Top_Icon=(props)=>{
    let appPath=appGetUrlPath();
 
    let title="";
    if(props.lsData.length!=0){
    title=props.lsData[0]["g_title"];
    }
    
    let topText="";
    if(props.lsData.length!=0){
        if(props.lsData[0]["col10"].trim()==="top_text"){
            title=props.lsData[0]["col1"];
            topText=props.lsData[0]["col2"]+props.lsData[0]["col3"];
        }
    
    }

    return(

        <div class="container-fluid icon-heading-para my11-5  bg-area111 pb-70px pt-70px">
        <h2 class="fw-bold mb-4">{title}</h2>
        <p>{<TextToHtml text={topText}/>}</p>
        <div class="features-container pt-5">
        {props.lsData.map((item,index)=>{
            
            if (item["col10"].trim()!=="top_text"){
                var urlTitle= item["col1"];
                 urlTitle=urlTitle.replace("?","_");
                 urlTitle=urlTitle.replace(" ","-");
                let clpUrl="..\\blog\\"+urlTitle+"?rf="+item["e_id"];
                 if(item["col7"]!==""){
                     clpUrl="..\\"+item["col7"];
                 }
                 if(item["col7"]==="read_now"){
                     //alert("i m in condition "+lsData[i]["col7"]+urlTitle+"?rf="+lsData[i]["e_id"]);
                     clpUrl="..\\"+item["col7"]+"\\"+urlTitle+"?rf="+item["e_id"];
                     //alert(clpUrl);
                 }
            return (
            <a href={clpUrl} class="feature-box border-box-2">
                
            <img style={{"width":"60px"}} class="mx-auto" src={appPath+item["col4"]} alt=""/>
                <div class="text-center overflowhide">
                    <h5>{item["col1"]}</h5>
                     <p class="p-0"> 
                     <TextToHtml text={item["col2"]}/>
                        </p>
                </div>
                
            </a>)
            }
        })
           }
           
           </div>
         
        </div>
   

    );
}

export default Comp_How_We_Top_Icon;