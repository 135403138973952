import {appGetUrlPath} from '../function_lib/common_cms';
import TextToHtml from '../sample_comp_v2/text_html';
import './comp_left_image_thumb.css';

const Comp_Left_Image_Thumb=(props)=>{
    let appPath=appGetUrlPath();

    let title="";
    if(props.lsData.length!=0){
    title=props.lsData[0]["g_title"];
    }

    
let topText="";
if(props.lsData.length!=0){
    if(props.lsData[0]["col10"].trim()==="top_text"){
        title=props.lsData[0]["col1"];
        topText=props.lsData[0]["col2"]+props.lsData[0]["col3"];
    }

}

    return(
        <section class="three_section_career11 pb111-70px pt11-70px">
        <div class="container mt-4">
            <div class="about-title heading">
        <h3 class="text-uppercase11 text-center running-underline title">{title}</h3>
        </div>
        <div class="row mt-4">
        {props.lsData.map((item,index)=>{
            if (item["col10"].trim()!=="top_text"){
                return <div data-aos="fade-down" data-aos-duration="1000"  class="col-12 col-md-4">
                <div class="card mr-3">
                    <div class="row g-0 h-100">
                        <div class="col-4 img-container" style={{
                            backgroundImage: `url('${appPath+item["col4"]}')`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat'
                            }}>
                            {/* <img src="/images/why_work_with_us.png"/> */}
                        </div>
                        <div class="col-8">
                            <div class="card-body text-start">
                                <h5 class="card-title">{item["col1"]}</h5>
                                <br/>
                                <p class="card-text font-weight-bold11">
                                <TextToHtml text={item["col2"]}/>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        })}
            
        </div>
    </div>
    </section>

    );
}

export default Comp_Left_Image_Thumb;